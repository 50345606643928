import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/data-access/services/authentication/authentication.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	standalone: false
})
export class NavBarComponent implements OnInit, AfterViewInit {
	@ViewChild('menuButtonAdmin')
	private menuButton1!: ElementRef;

	@ViewChild('menuButtonUser')
	private menuButton2!: ElementRef;

	@ViewChild('navbarList') navbarList!: ElementRef;
	@ViewChild('navbarContainer') navbarContainer!: ElementRef;
	@ViewChild('settingMenuSec') settingMenuSec!: ElementRef;
	@ViewChild('myProfileSec') myProfileSec!: ElementRef;

	@Input({ required: true }) public title: string | undefined;
	@Input({ required: true }) public userName = '';
	@Input({ required: true }) public data!: object;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public navbarItems: any;
	public isOpenPopup = false;
	public openUser = false;
	public openAdmin = false;

	constructor(
		public authenticationService: AuthenticationService,
		private renderer: Renderer2
	) {}

	ngAfterViewInit(): void {
		this.navbarItems = this.data;
		this.adjustNavbarItemsVisibility();
	}

	ngOnInit(): void {
		this.navbarItems = this.data;
		this.adjustNavbarItemsVisibility();
	}

	public openPopup(): void {
		this.isOpenPopup = !this.isOpenPopup;
	}

	public toggleUser(): void {
		this.openUser = !this.openUser;
	}

	public toggleAdmin(): void {
		this.openAdmin = !this.openAdmin;
	}

	public handleKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.openPopup();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public shouldRenderItem(item: any): boolean {
		if (item.type === 'link' && this.authenticationService.isAuthorized(item.requiredPermissions)) {
			return true;
		}

		if (item.type === 'menu' && this.authenticationService.isAuthorized(item.requiredPermissions)) {
			return true;
		}

		return false;
	}

	@HostListener('window:resize')
	onResize() {
		this.adjustNavbarItemsVisibility();
	}

	@HostListener('document:click', ['$event'])
	public onClick(event: Event): void {
		if (this.openUser && !this.menuButton2.nativeElement.contains(event.target as Node)) {
			this.openUser = false;
		}
		if (this.openAdmin && !this.menuButton1.nativeElement.contains(event.target as Node)) {
			this.openAdmin = false;
		}
	}

	private adjustNavbarItemsVisibility(): void {
		if (!this.navbarList?.nativeElement || !this.settingMenuSec?.nativeElement || !this.myProfileSec?.nativeElement) {
			return;
		}

		const containerWidth = this.navbarContainer.nativeElement.clientWidth;
		const settingMenuSecWidth = this.settingMenuSec.nativeElement.clientWidth;
		const myProfileSecWidth = this.myProfileSec.nativeElement.clientWidth;

		const availableWidth = containerWidth - settingMenuSecWidth - myProfileSecWidth;

		const items = Array.from(this.navbarList.nativeElement.children) as HTMLElement[];

		items.forEach((item) => this.renderer.setStyle(item, 'display', 'inline-block'));

		let totalWidth = 200;

		// Iterate from right to left, and hide items as necessary
		for (let i = 0; i < items.length; i++) {
			totalWidth += items[i].offsetWidth;

			if (totalWidth > availableWidth) {
				this.renderer.setStyle(items[i], 'display', 'none');
			}
		}
	}
}
