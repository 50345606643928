import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './shared/data-access/services/authentication/authentication.service';
import { UserInformationService } from './shared/data-access/services/user-information/user-information.service';
import navbarItems from './shared/ui/core/navbar/navbar-items';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: false
})
export class AppComponent implements OnInit {
	public title = 'Tarsan';
	public currentTenantTitle: string | undefined;
	public userName = '';
	public navbarItems = navbarItems;

	constructor(
		private userInformationService: UserInformationService,
		public authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		const userName = this.userInformationService.getFullName();
		this.userName = userName;
		this.currentTenantTitle = this.userInformationService?.getTenantTitle();
	}
}
